.lightbox-preview {
  max-height: 230px;
  display: block;
  cursor: pointer;
  border-radius: 10px;
  object-fit: cover;

  &.loading-img {
    border: none;
    background: url(../../../public/images/loader.gif) no-repeat center;
    min-width: 32px;
    min-height: 32px;
  }
}

figure {
  background-color: #525C8D;
  border: 2px solid #525C8D;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  width: fit-content;

  figcaption {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #fff;
    padding: 10px 10px 16px 10px;
    box-sizing: border-box;
  }
}
