.clip-icon {
    display: block;
    background-image: url(../../../public/images/file-upload-icon.svg);
    width: 25px;
    height: 26px;
    background-repeat: no-repeat; 
    opacity: 0.5;

    &.clip-icon-enabled {
        cursor: pointer;
        opacity: 1;
    }
} 
