.session-page__chat {
    flex-grow: 1;
    background-image: url(/public/images/session-bg-right.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    position: relative;
}

.session-page__chat-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    background-image: url(/public/images/session-bg-left.svg);
    background-repeat: no-repeat;
    background-position: top left;
}

.messages-container {
    overflow-y: auto;
    overflow-y: overlay;
    min-height: 0;
    flex-grow: 1;
    padding-top: 30px;
    padding-left: 4%;
    padding-right: 4%;
}

.typing-indicator-container {
    margin-bottom: 5px;
}

.typing-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    padding-left: 4%;
    padding-right: 4%;
    flex-shrink: 0;
}

.client-message-container {
    margin-bottom: 5px;
    animation-name: slowlyShow;
    animation-duration: 0.5s;
}

.expert-message-container {
    margin-bottom: 5px;
    margin-left: auto;
    text-align: right;
    animation-name: slowlyShow;
    animation-duration: 0.5s;
}

.expert-message-container, .expert-message-container figure {
    margin-left: auto;
}

@keyframes slowlyShow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* (Min Width: 1301px): bubble max width:65% */

@media only screen and (min-width: 1301px) {
    .message-max-width {
        max-width: 65%;
    }
}


/* Max width:1300px) and (Min width:1025px): bubble max width:75% */

@media only screen and (max-width: 1300px) and (min-width: 1025px) {
    .message-max-width {
        max-width: 75%;
    }
}


/* (Max width:1024px) and (Min width:901px): bubble max width:85% */

@media only screen and (max-width: 1024px) and (min-width: 901px) {
    .message-max-width {
        max-width: 85%;
    }
}


/* (Max width: 900px): bubble  max-width:95% */

@media only screen and (max-width: 900px) {
    .message-max-width {
        max-width: 95%;
    }
}

.client-message-container+.expert-message-container {
    margin-top: 20px;
}

.expert-message-container+.client-message-container {
    margin-top: 20px;
}
.chat-text-size-small{
    font-size:16px;
}
.chat-text-size-medium{
    font-size:18px;
}
.chat-text-size-large{
    font-size:22px;
}