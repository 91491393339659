.comparison-widget {
  height: 150px;

  .comparison-widget-title {
    padding: 16px;
    color: #303030;
    font-weight: 700;
  }

  .container {
    margin-top: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 35px;
    font-weight: 700;
    align-items: center;

    .divider {
      background-color: #BDC5C7;
      width: 2px;
      height: 60px;
    }
  }
}