.badgeAndText {
  display: flex;
  align-items: center;
  padding-top: 22px;

  &:first-child {
    margin-right: 32px;
  }

  .badge {
    padding: 2px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    min-width: 24px;
    min-height: 24px;
    max-height: 24px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
    background-color: #5adc20;
    border-radius: 22px;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }

  .text {
    font-weight: 600;
  }
}
