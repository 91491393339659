.slides {
    display: flex;
    flex-direction: column;
    width: 508px;
    height: 610px;
    box-sizing: border-box;
    padding: 0px;
    border-radius: 8px;
}

.slides .content {
    flex-grow: 1;
}

.slides .content > div {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1s;
    animation-name: fade;
    animation-duration: 1s;
}
  
@-webkit-keyframes fade {
    from {opacity: .2}
    to {opacity: 1}
  }
  
  @keyframes fade {
    from {opacity: .2}
    to {opacity: 1}
  }

.slides .nav {
    padding-bottom: 16px;
    text-align: center;
}

.slides .nav button {
    margin-bottom: 29px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    padding: 18px 29px;
}

.slides .dots span {
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin: 0 3px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.slides .dots span.active,
.slides .dots span:hover {
    background-color: #717171;
}

.slides .slide-1 img {
    margin-top: 70px;
    margin-bottom: 43px;
}

.slides .slide-1 h2 {
    margin-bottom: 14px;
}

.slides .slide-1 p {
    width: 327px;
    margin: 0 auto;;
    padding: 0 0px;
}

.slides .slide-2 img {
    margin-top: 34px;
    margin-bottom: 17px;
}

.slides .slide-2 h2 {
    margin-bottom: 13px;
}

.slides .slide-2 p {
    width: 327px;
    margin: 0 auto;;
    padding: 0 0px;
}

.slides .slide-3 img {
    width: 429px;
    margin-top: 52px;
    margin-bottom: 44px;
}

.slides .slide-3 h2 {
    margin-bottom: 13px;
}

.slides .slide-3 p {
    width: 324px;
    margin: 0 auto;;
    padding: 0 0px;
}

.slides .slide-4 > img {
    width: 429px;
    margin-top: 66px;
    margin-bottom: 40px;
}

.slides .slide-4 h2 {
    margin-bottom: 12px;
}

.slides .slide-4 p {
    width: 324px;
    margin: 0 auto;;
    padding: 0 0px;
}


.slides .slide-5 > img {
    height: 270px;
    margin-top: 52px;
    margin-bottom: 27px;
}

.slides .slide-5 h2 {
    margin-bottom: 12px;
}

.slides .slide-5 p {
    width: 324px;
    margin: 0 auto;;
    padding: 0 0px;
}





