.input-expert-message {
    height: 75px;
    width: 100%;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #303030;
    resize: none;
    border-width: 0px;
    outline: none;
    overflow: auto;
}

.input-expert-message :focus {
    border-width: 0px;
}

textaria .input-expert-message :focus {
    border-width: 0px;
}

/* Chrome, Firefox, Opera, Safari 10.1+ */
.input-expert-message::placeholder { 
opacity: 0.5; 
}

/* Internet Explorer 10-11 */
.input-expert-message:-ms-input-placeholder { 
opacity: 0.5; 
}

/* Microsoft Edge */
.input-expert-message::-ms-input-placeholder { 
opacity: 0.5; 
}
