.client-dob {
  display: flex;
  align-items: center;

  .zodiac-icon {
    transform: scale(0.75);
    margin-right: 5px;

    .circle {
      fill: var(--main);
    }
  }
}
