.slide-card {
  height: 200px;

  .slide-card-title {
    padding: 16px;
    color: #303030;
    font-weight: 700;
  }

  .slider-container {
    margin-top: 80px;

    .line {
      width: 100%;
      height: 3px;
      //background-color: #ccc;
      background: linear-gradient(to right, white 0%, #ccc 50%, white 100%);
      border-radius: 5px;
      position: relative;

      .expert-text {
        position: absolute;
        left: 300px;
        margin: -40px;
        font-size: 22px;
        font-weight: 700;
      }

      /* Styles for the slider thumb */
      .thumb-expert {
        width: 10px;
        height: 10px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        left: 270px;
        border-style: solid;
        border-width: 2px;
      }

      .thumb-average {
        width: 7px;
        height: 7px;
        background-color: #333;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        &.right{
          left: 325px;
        }
        &.left{
          left: 150px;
        }

      }

      .average-text {
        .text-container {
          text-align: center;
          position: absolute;
          top: 10px;

          
          .amount {
            display: block;
          }

          .subtitle-average {
            font-size: 13px;
            font-weight: 700;
            display: block;
          }
        }
        &.right .text-container{
          left: 270px;
        }
        &.left .text-container{
          left: 95px;
        }

      }
    }
  }
}
