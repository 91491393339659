.discount-menu {
    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
    }

    .react-horizontal-scrolling-menu--scroll-container {
        scrollbar-width: none;
        -ms-overflow-style: none;
        padding: 10px 0 28px 0;
    }

    .react-horizontal-scrolling-menu--inner-wrapper {
        position: relative;
        overflow-y: visible;
    }

    .react-horizontal-scrolling-menu--arrow-left,
    .react-horizontal-scrolling-menu--arrow-right {
        position: absolute;
        top: 34%;
    }

    .react-horizontal-scrolling-menu--arrow-left {
        left: -10px;
    }

    .react-horizontal-scrolling-menu--arrow-right {
        right: -10px;
    }
}