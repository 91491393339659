.session-last-readings {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 39px;
    padding-bottom: 49px;

    .header {
        margin-bottom: 29px;
        display: flex;

        @media (max-width: 700px) { 
            flex-direction: column;
         }

        .title {
            flex: 1;
        }
    }

    .empty {
        color: #666;
        text-align: center;
        font-size: 18px;
    }

    .reading-item {
        color: #666666;
        font-size: 14px;
        display: flex;
        margin-bottom: 19px;
        @media (max-width: 700px) { 
            flex-direction: column;
         }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &.viewed {
            color: #4387D9;
        }

        .time {
            flex: 1;
        }
    }

    .view-all {
        text-decoration: none;
        font-size: 12px;
        color: var(--main);

        &:hover {
            text-decoration: underline;
        }
    }
}

