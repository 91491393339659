.offer-btn-wrapper {
    .offer-btn {
        width: 44px;
        border-radius: 20px;
        border: 1px solid #D9D9D9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 10px 2px 10px;

        div {
            display: flex;
        }

        svg {
            fill: var(--main);
        }

        &:disabled svg {
            fill: #ADADAD;
        }
    }

    .offer-btn-tooltip {
        max-width: 173px;
        background: #fff;
        border-radius: 5px;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        border: 1px solid #D9D9D9;
        box-shadow: 0px 15px 15px 0px #0000001A;
        color: #303030;
        padding: 11px 10px 16px 12px;
    }
}