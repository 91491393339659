.client-card-messages {
    padding-top: 17px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-y: overlay;
    padding-right: 28px;
    .no-items {
        color: #666666;
        font-size: 14px;
        padding-top: 7px;
        margin-bottom: 10px;
    }
    .load-more {
        height: 55px;
    }
    .simple-loader {
        margin-top: 0!important;
        position: absolute;
        background-color: #fff;
        width: 100%;
        opacity: 0.5;
        padding-bottom: 20px;
    }

    .converted-label {
        padding: 5px 14.9px 6.6px 13.5px;
        border-radius: 16px;
        border: solid 1.5px #8dc440;
        background-color: #f0f6e5;
        margin: 0px 13px 17px 13px;
        font-size: 14px;
        font-weight: bold;
        color: #79aa33;
        line-height: 1.36;
        position: relative;

        .close-btn {
            position: absolute;
            background-image: url(../../../public/images/close-green.svg);
            background-size: cover;
            background-repeat: no-repeat;
            height: 12px;
            width: 12px;
            cursor: pointer;
            right: 12px;
            top: 9px;
        }

        .balloon {
            background-image: url(../../../public/images/converted-balloon.svg);
            background-size: cover;
            background-repeat: no-repeat;
            display: inline-block;
            height: 21px;
            width: 21px;
            text-align: center;
            line-height: 1.54;
            margin-right: 5px;
        }
    }
    .dob-wrapper {

        display: flex;
        align-content: center;
        justify-content: center;

    .dob-label{
        border-radius: 16px;
        border: solid 1.5px rgb(239, 107, 110);
        background-color: rgb(255, 255, 255);
        margin-bottom: 17.7px;
        
        display: flex;
        align-content: center;
        justify-content: center;

        padding: 0px 18px;

        .dob-text {
            font-family: Roboto;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            color: rgb(239, 107, 110);
            margin-top: 6.5px;
            margin-bottom: 6.5px;
            margin-left: 5.8px;
        }
    }
}
}

.client-card-messages-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    right: -28px;
    top: 0;
    display: flex;
    flex-direction: column;
}

.client-card-messages-input-area {
    margin-left: -28px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    padding: 15px 28px;
    .main-input-area {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        column-gap: 9px;
        margin-bottom: 13px;

        .offer-btn {
            padding: 4px 10px;
        }
    }
    .input-wrapper {
        flex-grow: 1;
        border-radius: 15px;
        border: solid 1px #d9d9d9;
        display: flex;
        padding: 13px 0px;
        padding-right: 13px;
        align-items: flex-end;
        &.readonly {
            background-color: #F4F4F4;
            position: relative;
            .emoji-icon-wrapper {
                opacity: 0;
            }
        }
        .close-btn {
            background-image: url(../../../public/images/close.svg);
            background-size: cover;
            background-repeat: no-repeat;
            height: 10px;
            width: 10px;
            cursor: pointer;
            display: inline-block;
            position: absolute;
            right: 13px;
            top: 13px;
            z-index: 2;
        }
        .DraftEditor-root {
            flex-grow: 1;
            outline: 0px solid transparent;
            //word-break: break-all;
            font-size: 15px;
            line-height: 20px;
            max-height: 300px;
            overflow-y: auto;
            // overflow-y: overlay;
            overflow-x: hidden;
            padding-bottom: 3px;
        }
    }
    .client-card-messages__send-button {
        background-color: var(--cta);
        color: #fff;
        padding: 8px 17px;
        font-family: "Roboto";
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        border: none;
        &[disabled] {
            opacity: 0.6;
        }
    }

    .client-birthday-is-coming {
        max-width: 375px;;
        font-family: Roboto;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #892788;
        margin-bottom: 7px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        span{
            margin-left: 15px;
        }

      }
}

.emoji-icon-wrapper {}

.emoji-icon {
    user-select: none;
}

.emoji-picker-wrapper {
    position: absolute;
    top: 0;
    margin-top: -428px;
}

.client-card-messages-disabled-input-area {
    padding-right: 101px;
    padding-top: 5px;
    margin-bottom: 15px;
    div {
        padding: 17px 0px 16px 20px;
        border-radius: 38px;
        border: solid 1px #d9d9d9;
        background-color: #e6e6ee;
        font-size: 15px;
        color: #706d6d;
    }
}

.messages-additional-actions {
    position: relative;
    display: flex;
    button {
        box-sizing: border-box;
        height: 34px;
    }
    button.active {
        box-shadow: 0 0 4px 0 rgba(137, 39, 136, 0.7);
        border: solid 1px #892788;
        color: #892788;
        font-weight: bold;
    }
    .template-button {
        margin-right: 10px;
    }
}