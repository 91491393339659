.DraftEditor-root {
  font-size: 14px;
  line-height: 19px;
  padding: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
  word-break: break-word;
}

.DraftEditor-root ul {
  padding-left: 40px;
}

.empty-notes {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  color: #666;
}

.note-editor {
  display: flex;
  transition: min-height 0.15s ease-out;

  &.new {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 9px;
    background-color: #f8f8f8;
    min-height: 41px;
    cursor: pointer;
    border: solid 1px #f8f8f8;
    display: flex;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1 1 auto;
      font-size: 14px;
      color: #666666;
      opacity: 0.5;
      position: relative;

      .add-note-icon {
        display: flex;

        &>div {
          display: flex;
          align-items: center;
        }

        circle {
          fill: var(--cta);
        }
      }
    }

    &:hover>div {
      opacity: 1;
    }
  }

  .note-editor__toolbar {
    display: flex;
    padding-left: 5px;
    padding-right: 11px;
    justify-content: space-between;
    padding-bottom: 8px;
    padding-top: 11px;

    .open-btn-tooltip {
      width: 158px;
      font-family: 'Roboto';
      font-size: 13px;
      font-weight: 400;
      line-height: 13px;
      text-align: left;
      padding: 8px 8px 8px 13px;
      background: #666666;
      opacity: 1;
      z-index: 1;
    }
  }

  &.not-editable {
    min-height: 150px;
    max-height: 150px;
    border-radius: 8px;
    border: solid 1px #aeadad;
    color: #666;

    .DraftEditor-root {
      position: absolute;
      bottom: 0;
      min-height: 96px;
    }
  }

  &.editable {
    min-height: 150px;
    border-radius: 8px;
    cursor: text;
    border: solid 1px var(--main);
  }
}

.note-editor__editor-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 13px;
}

.note-editor__editor-wrapper>div:first-child {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.note-editor__toolbar .icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center center;
  border-radius: 0;
}

.note-editor__toolbar .icon:hover {
  background-color: #ddd;
}

.note-editor__toolbar .bold {
  background-image: url(../../../public/images/editor-bold-icon.svg);
}

.note-editor__toolbar .italic {
  margin-left: 1px;
  background-image: url(../../../public/images/editor-italic-icon.svg);
}

.note-editor__toolbar .underline {
  margin-left: 2px;
  background-image: url(../../../public/images/editor-underline-icon.svg);
}

.note-editor__toolbar .bullets {
  background-image: url(../../../public/images/editor-bullets-icon.svg);
  background-position-y: 5px;
  margin-left: 6px;
}

.note-editor__toolbar button.save {
  font-size: 14px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  color: var(--main);
}

.note-editor__toolbar .last-update {
  font-size: 12px;
  color: #aeadad;
  padding-top: 3px;
  padding-left: 6px;
  text-transform: uppercase;
}

.note-editor__toolbar .open {
  margin: 0;
  font-size: 14px;
  padding: 0;
  color: #aeadad;
  font-weight: normal;
}