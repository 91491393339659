.session-transcript {
    .transcript-text {
      margin-left: 29px;
      padding-right: 15%;
      padding-bottom: 20px;
      overflow-y: auto;
      max-height: calc(100vh - 76px);
    }
    .transcript-footer{
      position: fixed;
      bottom: 0;
      width: 28%;
      height: 21px;
      z-index: 1;
      background-color: white;
    }
    .transcript-error{
      text-align: center;
      margin-top: 10px;
    }
  }