.file-upload-item-container
{    
       
    background-color: #ffffff;

    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal; 
    letter-spacing: normal;
    text-align: left;
    color: #303030;
    line-height: 1.33;
    font-size: 12px;
    
    margin: 0 11px;

    display: flex;
    justify-content: space-between;
    align-items: center;

}
.name-and-status{
 display: inline-flex;
    justify-content: flex-start;
    align-items:center;  
    height: 41px;
    margin-right: 10px;
}


.file-name-container {
    width: 200px; 
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 

    font-size: 14px;
    line-height: 1.36;
    margin-right: 20px;
    
}

.uploading-progress {
    margin: 0;
    border-radius: 5px;

    height: 6px;
    border-radius: 40px;
    background-color: #892788;

}

.file-error-container {
    color: #ce3d3d;
}
/* was close-icon.svg*/
.close-icon {
    display: block;
    background-image: url(/public/images/close.svg);
    width: 13.4px;
    height: 13.4px;
    cursor: pointer;
}


.close-icon-container{
    
}


.file-status-container {
   width: 200px; 
}


.uploading-title{
    color: #892788;
}

.file-uploaded-container{
    width: 200px; 
}
.file-uploading-container {
    width: 200px; 
}

