.client-detail {
    height: 16px;
    margin-bottom: 15px;
}

.client-detail h1 {
    height: 39px;
    font-family: Roboto;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #303030;
}

.avatar-and-name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.client-name {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}

.client-name h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
}

.client-name a {
    text-decoration: none;
    color: #303030;
}

.client-name a {
    text-decoration: none;
    color: #303030;
}

.client-name_fav {
    margin-left: 11px;
}

.client-name__device {
    margin-left: auto;
}

.last-reading {
    margin-bottom: 5px;
}

.mute-text .returning-client-icon {
    margin-left: 7px;
}

.via-category {
    margin-bottom: 5px;
}

.client-dob {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    color: #666666;
    margin: 8px 0;
}