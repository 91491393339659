.sliding-button {
    height: 29px;
    background: #892788;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
    border-radius: 60px 0px 0px 60px;
    position: absolute;
    right: 0;
    top: 170px;
    display: flex;
    padding: 6px;
    z-index: 1000;

    button {
        padding: 0;
    }

    &__icon {
        display: flex;

        &__border {
            width: 2px;
            background: #70216F;
            border-right: 1px solid #A232A0;
        }

        &__collapsed {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            margin-right: 5px;
        }

        &__expanded {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 14px;
            margin-right: 18px;
        }
    }

    &__content {
        overflow: hidden;
        width: 0;
        transition: width 0.75s;
        -webkit-transition: width .75s;
        -moz-transition: width 0.75s;

        &.expanded {
            overflow: visible;
        }

        button {
            height: 100%;
            color: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            margin-left: 28px;
            margin-right: 41px;
            white-space: nowrap;
        }
    }
}

.tooltip-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 1;
}
