.transcript-header {
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6ee;
  background-color: #ffffff;

  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  cursor: pointer;

  img {
    width: 8px;
    height: 14px;
    object-fit: contain;
    margin-left: 13px;
    margin-right: 8px;
  }

  .transcript-date {
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #303030;
  }
  .put-to-center {
    position: absolute;
    top: 0px;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
