.scb-wrapper {
  display: inline-block;
  .simple-loader {
    margin-top: 0px !important;
  }
}

button.scb-save {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  border: none;
  text-align: center;
  color: #fff;
  background-color: var(--main);

  padding: 6px 13px;
}

button.scb-save[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
