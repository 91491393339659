.cs-table-name {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #303030;

  margin-bottom: 20px;
  margin-top: 8px;
}

.cs-table-name .cl-sub-name {
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-left: 11px;
  text-transform: uppercase;
}

.cl-sub-name .inline-spinner {
  display: inline-block;
}
