.sliding-segmented-control {
  display: flex;
  background-color: #eceef2;
  padding: 1px 0 2px 0;
  border-radius: 8px;
}

.segment {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  border-radius: 8px;

  &.selected {
    margin: 0.5px;
    box-sizing: content-box;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.03);
    background-color: #fff; /* Set the selected background color */
  }
}
