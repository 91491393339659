.search-wrapper {
    position: relative;
    .input-search {
        width: 300px;
        height: 39px;
        margin-right: 20px;
        padding: 0px 35px 0px 20px;
        border-radius: 60px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px #cccccc;
        background-color: #ffffff;
        position: relative;
        color: #303030;
    }
    input:disabled {
        background-color: rgba(102, 102, 102, 0.1);
    }
    .to-top {
        z-index: 11;
    }
    
    .clear-icon {
        display: inline-block;
        width: 9.4px;
        cursor: pointer;
        position: absolute;
        top: 15px;
        left: 329px;
        z-index: 11;
    }
    .input-search:focus {
        outline: none;
        border: solid 1px var(--main);
    }
}