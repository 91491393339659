.gender-icon {
  display: block;
  width: 20px;
  height: 20px;
  mask-size: contain;
  
  &.female, &.male, &.other {
    background-color: #f89b22;
  }

  &.female {
    mask-image: url(../../../public/images/female.svg);
  }

  &.male {
    mask-image: url(../../../public/images/male.svg);
  }

  &.other {
    mask-image: url(../../../public/images/other.svg);
  }
}
