.session-notes {
    padding-left: 10%;
    padding-right: 10%;
    border-top: 9px solid #FAFAFD;
    border-bottom: 9px solid #FAFAFD;
    padding-top: 39px;
    padding-bottom: 49px;
}

.session-notes .title {
    margin-bottom: 9px;
}