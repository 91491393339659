.onboard-slides {
    display: flex;
    flex-direction: column;
    width: 508px;
    height: 610px;
    box-sizing: border-box;
    padding: 0px;
    border-radius: 8px;
}

.onboard-slides .content {
    flex-grow: 1;
}

.onboard-slides .content > div {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1s;
    animation-name: fade;
    animation-duration: 1s;
}
  
@-webkit-keyframes fade {
    from {opacity: .2}
    to {opacity: 1}
  }
  
  @keyframes fade {
    from {opacity: .2}
    to {opacity: 1}
  }

.onboard-slides .nav {
    padding-bottom: 16px;
    text-align: center;
}

.onboard-slides .nav button {
    margin-bottom: 29px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    padding: 18px 29px;
}

.onboard-slides .dots span {
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin: 0 3px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.onboard-slides .dots span.active,
.onboard-slides .dots span:hover {
    background-color: #717171;
}

.onboard-slides .slide-1 img {
    margin-bottom: 52px;
}

.onboard-slides .slide-1 h2 {
    margin-bottom: 14px;
}

.onboard-slides .slide-1 p {
    padding: 0 0px;
}

.onboard-slides .slide-2 img {
    margin-top: 78px;
    margin-bottom: 52px;
}

.onboard-slides .slide-2 h2 {
    margin-bottom: 13px;
}

.onboard-slides .slide-2 p {
    padding: 0 78px;
}

.onboard-slides .slide-3 img {
    margin-top: 72px;
    margin-bottom: 53px;
}

.onboard-slides .slide-3 h2 {
    margin-bottom: 13px;
}

.onboard-slides .slide-3 p {
    padding: 0 78px;
}

.onboard-slides .slide-4 > img {
    margin-top: 65px;
    margin-bottom: 19px;
}

.onboard-slides .slide-4 h2 {
    margin-bottom: 12px;
}

.onboard-slides .slide-4 p {
    padding: 0 78px;
    margin-bottom: 31px;
}

.onboard-slides .slide-4 .test-ring * {
    vertical-align: middle;
}

.onboard-slides .slide-4 .test-ring img {
    margin-right: 10px;
    margin-bottom: 2px;
}

.onboard-slides .slide-4 .test-ring {
    font-size: 20px;
    font-weight: bold;
    color: #892788;
    line-height: 1.5;
    cursor: pointer;
    display: inline-block;
}




