.modal-content.feedback-form {
    width: 630px;
}

.modal-content.feedback-form .close {
    background-image: url(/public/images/close.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 17px;
    width: 17px;
    position: absolute;
    right: 26px;
    top: 23px;
    cursor: pointer;
}