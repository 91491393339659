.chart-part {
  display: flex;
  flex-direction: column;

  padding: 16px 16px 0 16px;

  .chart-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 2px;
    line-height: 1;
    margin-bottom: 3px;

    .chart-title {
      flex: 1 1 auto;
      & > span {
        font-weight: 700;
      }
    }

    .chart-total-amount {
      font-weight: 700;
    }
  }
  .chart-tooltip {
    color: #7d7d7d;
    font-size: 11px;
    font-weight: 600;
  }

  .chart-container {
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.detail-part {
  padding: 16px;
  background: linear-gradient(#eceff3, #fff);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;

  .no-earnings {
    font-size: 15px;
    font-weight: 700;
    color: #7d7d7d;
  }

  .day-detail {
    display: flex;
    flex-direction: row;
    width: 100%;

    .day-detail-left {
      flex: 4;
      margin-right: 4px;
      display: flex;
      flex-direction: column;

      .day-detail-date {
        line-height: 1;
        font-size: 13px;
        font-weight: 600;
        color: #7d7d7d;
      }

      .day-detail-amount {
        font-size: 22px;
        font-weight: 700;
      }
    }

    .day-detail-right {
      flex: 6;
      display: flex;
      row-gap: 3px;
      flex-direction: column;
      overflow: hidden;

      .day-detail-item {
        line-height: 1;
        font-size: 13px;
        span {
          font-weight: 700;
        }
      }
    }
  }
}
