.client-avatar {
    margin-right: 13px;
    position: relative;

    .default-avatar,
    img {
      height: 44px;
      width: 44px;
      border-radius: 50%;
      object-fit: cover;
    }

    .default-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);

      & > div {
        display: flex;
      }
    }

    .gender-wrapper {
      display: flex;
      position: absolute;
      bottom: -5px;
      right: -6px;
      border-radius: 50%;
      border: 1px solid #fff;
      background: #f89b22;

      .gender-icon {
        background: #fff;
      }
    }
  }