.help-widget-icon {
  display: inline-block;
  background-size: 100% 25px;
  
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  cursor: pointer;
  
}

.help-widget-icon.without-red-point{
  background-image: url(/public/images/help-widget-icon.svg);
}
.help-widget-icon.with-red-point {
  background-image: url(/public/images/help-widget-icon-red-point.svg);
}