.modal-content.group-message-modal {
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e6e6ee;
    display: flex;
    flex-direction: column;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    width: 450px;
    padding: 55px;

    .close {
        background-image: url(../../../public/images/close.svg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 17px;
        width: 17px;
        cursor: pointer;
        display: block;
        position: absolute;
        right: 27px;
        top: 23px;
    }

    h1 {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: #892788;
    }

    .title-description {
        margin-top: 6px;
    }

    .available-groups-container {
        height: 140px;
        border-bottom: 1px solid #ADADAD;
        margin-bottom: 25px;

        .available-groups {
            margin-top: 20px;

            .actions {
                margin-top: 8px;
                flex-direction: row;
                flex-flow: nowrap;
                display: flex;
                width: 478px;

                button {
                    border-radius: 20px;
                    padding: 8px 15px 8px 15px;
                    border: 1px solid #892788;
                    color: #892788;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: center;
                    margin-right: 18px;
                    height: 36px;

                    &.group-selected {
                        color: #fff;
                        background: #892788;
                    }
                }

                .total-clients {
                    flex: auto;
                    display: flex;
                    align-items: center;
                    justify-content: right;
                    font-family: Roboto;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: right;

                    .amount-display {
                        color: #892788
                    }
                }
            }
        }

        .sixty-days-note {
            margin-top: 6px;
            width: 350px;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #CE3D3D;
        }
    }

    .promotion-settings {
        display: flex;
        margin-bottom: 15px;
        align-items: end;

        label {
            font-size: 12px;
            color: #666;
            margin-bottom: 9px;
            display: block;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .discount-type-block {
            width: 153px;
            margin-right: 18px;
        }

        .discount-value-block {
            width: 93px;
            margin-right: 45px;

            input[type="number"] {
                font-size: 16px;
                width: 100%;
                border: solid 1px #aeadad;
                border-radius: 4px;
                min-height: 38px;
                margin: 0;
                padding: 0;
                outline: none;
                box-sizing: border-box;
                -moz-appearance: textfield;
            }

            div {
                position: relative;
                background-color: #dedede;
                display: inline;

                &.fixed {
                    &::before {
                        position: absolute;
                        top: 0;
                        z-index: 1;
                        content: '$';
                        left: 9px;
                    }

                    input[type="number"] {
                        padding-left: 22px;
                    }
                }

                &.percentage {
                    &::after {
                        position: absolute;
                        top: 0;
                        z-index: 1;
                        content: '%';
                        right: 13px;
                    }

                    input[type="number"] {
                        padding-left: 10px;
                    }
                }
            }
        }

        .discount-expire-block {
            width: 102px;
        }

        .discount-close-block {
            margin-left: 10px;

            button {
                width: 51px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 13px;

                img {
                    height: 10px;
                    width: 10px;
                }
            }
        }
    }

    .summary {
        height: 16px;
        font-size: 12px;
        margin-bottom: 23px;

        strong {
            font-weight: bold;
        }

        span {
            text-decoration: line-through;
        }

        .discount-errors {
            color: red;
        }
    }

    .message-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        .message {
            margin-right: 12px;
        }

        .promotion-message-button {
            height: 36px;
        }
    }

    .input-wrapper {
        border: solid 1px #707070;
        border-radius: 8px;
    }

    textarea {
        border-radius: 8px;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        display: block;
        width: 100%;
        height: 119px;
        margin-top: 5px;
        line-height: 1.33;
        padding: 10px;
        resize: none;
        border: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    textarea:focus {
        outline: none !important;
        border-color: #892788;
    }

    textarea:read-only {
        background-color: #f4f4f4;
        border: solid 1px #707070 !important;
    }

    .readonly-part {
        color: #666;
        white-space: break-spaces;
        margin: 0 10px;
        padding: 10px 0;
        border-top: 1px;
        border-style: dashed;
    }

    .error-message {
        margin-top: 5px;
        color: red;
        height: 21px;
        font-size: 13px;
    }

    .error {
        border-color: red !important;
    }

    button {
        &.submit {
            margin-top: 23px;
            width: 100%;
        }

        &.disabled,
        &:disabled {
            color: #ADADAD !important;
            border: 1px solid #ADADAD !important;
            background: #fff !important;
        }
    }
}
