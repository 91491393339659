.close-sign {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    cursor: pointer;
    display:flex; 
    justify-content: center;
    align-items: center;

  .close{
    background-image: url(../../../public/images/close.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 9px;
    width: 9px;
    display: inline-block;
  }
}










