.dashboard {
  background-color: rgba(234, 234, 245, 0.25);

  .context-to-center {
    margin: auto;
    max-width: 1366px;
  }
  .dashboard-title {
    margin-top: 39px;
    margin-bottom: 20px;

    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #303030;
    text-transform: uppercase;
  }

  .content-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    
    .column {
      width: 550px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      
      @media (max-width: 600px) {
        gap: 8px;
      }
    }
  }
}
