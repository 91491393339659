.discount-card {
    cursor: pointer;
    user-select: none;
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 144px;
    height: 90px;
    background-repeat: no-repeat;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
    margin-right: 12px;

    &.selected {
        box-shadow: none;
        &.with-mark::after {
            content: url('../../../public/images/check-mark.svg');
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 26px;
            height: 26px;
            border: 2px solid #fff;
            border-radius: 50%;
            background: #7BD137;
            box-sizing: border-box;
            top: -7px;
            right: -6px;
        }

        .value, .subtitle {
            color: #fff;
        }
    }

    &.slim {
        width: 175px;
        height: 77px;
        .value, .subtitle {
            color: #fff;
            left: 13.31px;
        }

        margin: -15px 0 20px 0;

        .value {
            top: 14px;
        }

        .subtitle {
            bottom: 17px;
        }
    }

    .value {
        font-family: "Roboto";
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        position: absolute;
        top: 20px;
        left: 13px;
    }

    .subtitle {
        font-family: "Roboto";
        font-size: 13px;
        font-weight: 400;
        line-height: 15.23px;
        text-align: left;
        color: #666;
        position: absolute;
        bottom: 26px;
        left: 13px;
    }
}