.login-container {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;

  .login-form-container {
    padding-left: 89px;

    .login-title {
      margin-top: 12px;
      color: #303030;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;

      div {
        color: #615573;
        font-size: 14px;
        font-weight: 400;
        line-height: normal; 
      }
    }

    .login-form {
      margin-top: 10px;
      max-width: 325px;
      .error {
        color: #ce3d3d;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 11px; ;
      }

      .input-container {
        margin-bottom: 21px;
        .input-label {
          display: block;
          color: #303030;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 5px;
          padding-left: 12px;
        }
        .input-field {
          color: #303030;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          background-color: #f8f8f8;
          height: 49px;
          width: 265px;
          border-style: none;
          border-radius: 7px;
          padding-left: 16px;
          padding-right: 2px;

          &::placeholder {
            color: #615573;
          }
          &:-ms-input-placeholder {
            color: #615573;
          }
          &::-webkit-input-placeholder {
            color: #615573;
          }

          &:focus {
            outline: none;
            box-shadow: none;
            border: none;
          }
        }
      }
      .button-container {
        margin-top: 33px;
        button {
          color: #fff;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          background-color: var(--cta);
          width: 325px;
          height: 60px;
          flex-shrink: 0;
          border-radius: 70px;

          &:disabled {
            opacity: 0.5;
          }
        }
      }

      .checkbox-container {
        margin-top: 30px;
        display: flex;
        align-items: center;
        .checkbox-field {
          width: 23px;
          height: 23px;
          margin-right: 9px;
          border: 1px solid #666;

          &:checked {
            accent-color: var(--main);
          }
        }
        label {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 18.75px;
          text-align: left;
        }
      }
    }
    .forgot-password {
      margin-top: 25px;
      span {
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;
        color: var(--main);
      }
    }
    .social-network-container {
      margin-top: 33px;
      .or-connect {
        color: #636570;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
      }
      .social-network-login {
        display: flex;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
        button {
          display: flex;
          width: 146px;
          height: 48px;
          padding: 0px 16px 0px 16px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #dfe2ef;
          background: #fff;
          margin-right: 16px;
          img {
            padding: 5px;
            margin-right: 8px;
          }
          span {
            color: #121212;
            text-align: center;

            /* Text Large/Large Bold */
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 25.5px */
          }
        }
      }
    }
  }
}
