.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 23px;
    padding-right: 19px;
}

.topBar .expert-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.topBar .logo {
    padding-top: 9px;
    padding-right: 52px;
}

.settings-wrapper {
    position: fixed;
    width: 375px;
    z-index: 1010;
    right: 0;
    bottom: 0;
    top: 0;
}

.top-bar--right-menu-holder {
    display: flex;
    align-items: center;
}

.top-bar--right-menu-holder .tutorial-video {
    font-size: 12px;
    background-color: #4387d9;
    height: 31px;
    display: block;
    text-decoration: none;
    color: #fff;
    border-radius: 15px;
    margin-right: 40px;
    padding: 9px 15px 0px 15px;
    box-sizing: border-box;
}

.top-bar--right-menu-holder .tutorial-video * {
  vertical-align: middle;
}

.top-bar--right-menu-holder .tutorial-video i {
    display: inline-block;
    background-image: url(/public/images/tutorial-video-icon.svg);
    background-repeat: no-repeat;
    width: 17px;
    height: 13px;
    margin-right: 8px;

}