.instant-message {
    border-radius: 10px;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #d9d9d9;
    margin-bottom: 14px;
    padding: 16px;
    position: relative;

    &.expert-instant-message {
        border: 1px solid #ADADAD;
    }

    &.scheduled {
        border-style: dashed;
        border-color: #000;

        .message-status .status-icon  {
            width: 18px;
            height: 18px;
            background-image: url(../../../public/images/scheduled-message-icon.svg);
        }
   
    }

    &.client-instant-message {
        background-color: var(--client-message-bg);
        color: var(--client-message-text);
    }
}

.message-status * {
    vertical-align: middle;
}
.message-status {
    position: absolute;
    right: 24px;
    font-size: 14px;

    .status-icon {
        display: inline-block;
        background-size: 100%;
        width: 20px;
        height: 15px;
        background-repeat: no-repeat;
        background-image: url(../../../public/images/unread-message-icon.svg);
        margin-right: 6px;
        &.read {
            background-image: url(../../../public/images/read-message-icon.svg);
        }

        &.discount {
            width: 18px;
            height: 21px;
            background-image: url(../../../public/images/discount-message-icon.svg);
        }
    }
    
}

.instant-message__header {
    display: flex;
    margin-bottom: 25px;
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.instant-message__content {
    word-break: break-word;
    white-space: pre-line;
    font-family: "Roboto";
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;


    .separator {
        width: 100%;
        border-bottom: 1px solid #D9D9D9;
        margin-top: 20px;
        margin-bottom: 8px;
    }

    .expiry-info {
        color: #9B9B9B;
        font-size: 12px;
        line-height: 16px;
    }

    .discount-card {
        cursor: default;
    }
}

.instant-message__user-name {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 3px;
}

.instant-message__sent-date {
    font-size: 12px;
}