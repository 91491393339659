.settings-container {
    position: fixed;
    width: 375px;
    z-index: 1010;
    right: 0;
    bottom: 0;
    top: 0;
    box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    user-select: none;
    .settings-controls {
        .close {
            background-image: url(../../../public/images/close.svg);
            background-size: cover;
            background-repeat: no-repeat;
            height: 17px;
            width: 17px;
            cursor: pointer;
            display: inline-block;
            position: absolute;
            right: 17px;
            top: 17px;
        }
    }
    .settings-content {
        margin-left: 29px;
    }
    .settings-title {
        margin-top: 55px;
        margin-bottom: 35px;
        font-family: Roboto;
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
    }
    .title {
        object-fit: contain;
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #892788;
        text-transform: uppercase;
    }
    .sub-title {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 15px;
    }
}