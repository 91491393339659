.modal-content.forgot-password-modal {
  width: 322px;
  padding: 33px, 40px, 30px, 40px;
  border-radius: 8px;

  h2 {
    color: #303030;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px; /* 135% */
    margin-bottom: 22px;
  }
  p {
    color:  #121212;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }
  .email-form {
    margin-top: 24px;

    .input-container {
      margin-bottom: 21px;
      .input-label {
        display: block;
        color: #303030;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-bottom: 8px;
        padding-left: 2px;
        text-align: left;
      }
      .input-field {
        color: #303030;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background-color: #f8f8f8;
        height: 48px;
        width: 323px;
        border-style: none;
        border-radius: 8px;
        padding-left: 12px;
        padding-right: 2px;

        &::placeholder {
          color: #615573;
        }
        &:-ms-input-placeholder {
          color: #615573;
        }
        &::-webkit-input-placeholder {
          color: #615573;
        }

        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }
    }
    .button-container {
      margin-top: 33px;
      button {
        color: #fff;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        background-color: var(--cta);
        width: 325px;
        height: 60px;
        flex-shrink: 0;
        border-radius: 70px;

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .back-to-login {
    margin-top: 24px;
    span {
      color: var(--main);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      cursor: pointer;
    }
  }
}
