.network-status {
  font: bold 14px "Roboto";
  line-height: 16.41px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;

  &.down {
    background: #ce3d3d;
    color: #fff;
    transform: scaleY(1);
  }
}
