.client-list-continer {
  display: flex;
  min-width: 900px;
}
.client-list-continer .filter-preset {
  flex: 10%;
  margin-top: 10px;
}

.client-list-continer .result {
  flex: 90%;
  border-left: 1px solid #e6e6ee;
  padding-top: 10px;
  position: relative;
}

.cl-cover-table {
  background-color: rgba(102, 102, 102, 0.1);
  z-index: 10;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
}

.cl-cover-result {
  background-color: rgba(102, 102, 102, 0.1);
  z-index: 10;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
}

.cl-cover-result .simple-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0);
}

.cl-page-center {
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: 11;
}

.cover-screen {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(48, 48, 48, 0.3);
  z-index: 2;
}
.wem-banner-container {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
}
.wem-banner-image-container {
  max-width: 100%;
  max-height: 100px;
  overflow: 'hidden'; 
}
.wem-banner-image {
  width: auto;
  height: 100px;
  max-width: 100%;
  object-fit: cover;
}

