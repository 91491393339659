.last-readings {
  .no-items {
    padding-top: 20px;
    text-align: center;
  }

  table {
    // table-layout: fixed;
    width: 100%;
    color: #666666;
    font-size: 14px;
    border-collapse: collapse;
  }

  tbody tr:hover {
    td:first-child {
      text-decoration: underline;
    }
  }

  tr.viewed {
    color: #4387d9;
  }

  td {
    padding-top: 8px;
    padding-bottom: 8px;
    vertical-align: middle;
  }

  tr:nth-child(2) td {
    padding-top: 6px;
  }

  td,
  th {
    text-align: left;
    white-space: nowrap;
  }

  .date-column {
    width: 200px;
  }

  .duration-column {
    width: 90px;
  }

  .cost-column {
    width: 80px;
  }
  .view-column {
    width: 50px;
  }

  .face-icon {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .tool-tip-up {
    z-index: 1000;
  }
  td.rating {
    font-weight: bold;
  }

  td.view {
    cursor: pointer;
  }

  & td.view {
    color: var(--cta);
  }

  tr.no-icon{
    margin-top:4xp;
    margin-bottom: 4px;
    height: 35px;
  }
}
