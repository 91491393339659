.fav-red-icon {
    display: block;
    background-image: url(/public/images/fav-red-icon.svg);
    width: 17px;
    height: 15px; 
    cursor: pointer;
    background-repeat: no-repeat; 
} 

.fav-white-icon {
    display: block;
    background-image: url(/public/images/fav-white-icon.svg);
    width: 17px;
    height: 15px; 
    cursor: pointer; 
    background-repeat: no-repeat;
} 
