.typing-area {
    height: 105px;
    box-shadow: 0 2px 7px 0 #cfc0f3;
    background-color: #ffffff;
    display: flex;
    flex: 1;
    border-radius: 10px;

    .preview-container {
        position: relative;
        margin-top: 19px;
        margin-right: 26px;

        .close-container{
            position: absolute;
            top: -10px;
            right: -10px;
            z-index: 5;
        }

        .preview-img-container{
            position: relative;
            
            width: 54px;
            height: 54px;
            box-sizing: border-box;
            overflow: hidden;
            
            border-radius: 9px;
            border: solid 1px #303030;

        }
        .preview {
           
            overflow: hidden;
            min-width: 100%;
            max-height: 100%;
            min-height: 100%;

            &.loading{
                opacity: 0.5;
            }
        }

        .uploading-progress {
            margin: 0;
        
            height: 7px;
            border-top-left-radius:10px;
            border-top-right-radius:5px;
            border-bottom-right-radius:5px;
            background-color: #892788;

            position: absolute;
            bottom: 4px;     
            z-index: 5;
        }
        .progress-background{
                width: 100%;
                height: 7.1px;

                border-bottom-left-radius:10px;
                border-bottom-right-radius:10px;

                opacity: 0.8;
                background-color: #666;

                position: absolute;
                bottom: 4px;     
                z-index: 3;
        }
    }

    .avatar-container {
        margin-right: 11px;
        margin-left: 14px;
        margin-top: 14px;
        display: inline-block;
    }

    .input-container {
        margin-top: 10px;
        flex: 1;
        margin-bottom: 9px;
    }

    .typing-area:focus-within{
        box-shadow: 0 2px 7px 0 #896389;
    }

    .clip-container{
        margin-left:0px;  
        margin-right: 25px;
        margin-top:12px;
        opacity: 0.5;

        &.icon-disabled{
            opacity: 0.2;
        }
    }
}

.emoji-container{
  margin-left:30px;  
  margin-top:12px;
  margin-right:16px;  
}

.open-emoji {
    opacity: 1;   
}

.close-emoji {
    opacity: 0.5;   
}

.emoji-picker-container{
    margin-top: -426px;
    position: absolute;
    right: 4%;
    z-index: 1000;
}