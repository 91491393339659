.container {
  width: 100%;
  height: 100%;
  background-color: #f4f4fd;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
 z-index: 10;
}