
.typing-indicator {
    width: 69px;
    height: 34px;
    border-radius: 24px;
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.bubblebox {
    height: 17px;
}

.bubble{
    background-color: #892788;
}
/* animation-name: mercuryTypingAnimation;
    animation-duration: 1.5s infinite ease-in-out; */
.bubble {
    animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
    border-radius: 50%;
    display: inline-block;
    height: 9px;
    margin:2px 3px;
    width: 9px;
}

@keyframes mercuryTypingAnimation {
  0%{
    -webkit-transform:translateY(0px)
  }
  28%{
    -webkit-transform:translateY(-5px);
    background-color:#892788;
  }
  44%{
    -webkit-transform:translateY(0px);
      background-color:#892788
  }
}


.bubble:first-child{
 animation-delay:200ms;
}
.bubble{
 animation-delay:300ms;
}
.bubble:last-child{
 animation-delay:400ms;
}

