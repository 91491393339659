.cl-paging {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #303030;
}

.cl-paging select {
  border-width: 0px;
  margin-right: 35px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
}

.cl-paging select:focus {
  border-width: 0px;
  outline: none;
}

.cl-paging label {
  margin-right: 10px;
}

.cl-paging .records {
  margin-right: 23px;
}

.cl-paging i {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.cl-paging .first {
  background-image: url(/public/images/first-page-icon.svg);
  margin-right: 15px;
}

.cl-paging .prev {
  background-image: url(/public/images/prev-page-icon.svg);
  margin-right: 30px;
}
.cl-paging .next {
  background-image: url(/public/images/next-page-icon.svg);
  margin-right: 15px;
}
.cl-paging .last {
  background-image: url(/public/images/last-page-icon.svg);
}
