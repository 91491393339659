.session-page {
    display: flex;
    flex-grow: 1;
}

.session-page__left-column {
    flex: 30%;
    background-color: #FEFEFE;
    position: relative;
}

.session-page__left-column_wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    overflow-y: auto;

    .title {
        color: var(--main);
    }
}

.session-page__right-column {
    flex: 70%;
    background-color: #FAFAFD;
    display: flex;
    flex-direction: column;
    position: relative;
}

.session-page__left-column .client-detail-container {
    margin-top: 39px;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 36px;
}

