.select-coupon-modal {
    padding: 50px;
    font-family: 'Roboto';
    text-align: left;
    font-weight: 400;
    line-height: 17.58px;
    width: 561px;
    box-sizing: border-box;
    border-radius: 20px;

    .close {
        background-image: url(../../../public/images/close.svg);
        background-size: cover;
        background-repeat: no-repeat;
        height: 17px;
        width: 17px;
        position: absolute;
        right: 26px;
        top: 23px;
        cursor: pointer;
    }

    .header {
        h3 {
            font-size: 20px;
            font-weight: 700;
            line-height: 23.44px;
            color: var(--cta);
            margin-bottom: 7px;
        }

        p {
            font-size: 15px;
        }
    }

    .discount-block {
        margin-top: 25px;
    }

    h5 {
        font-size: 15px;
    }

    .message-block {
        font-weight: 400;
        text-align: left;

        .message-area-wrapper {
            margin-top: 10px;
            margin-bottom: 23px;
            min-height: fit-content;
            border: 1px solid #707070;
            border-radius: 10px;
            padding: 17px 17px 14px 15px;

            .message-area {
                border: none;
                resize: none;
                outline: none;
                field-sizing: content;
                width: 100%;
                min-height: 100px;
                font-size: 15px;
                line-height: 20px;
                white-space: pre-line;
                font-family: 'Roboto';
            }
        }

        strong {
            font-weight: bold;
        }

        .separator {
            width: 100%;
            border-bottom: 1px solid #D9D9D9;
            margin-top: 17px;
            margin-bottom: 13px;
        }

        .expiry-info {
            color: #9B9B9B;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .send-promotion-btn {
        background: var(--cta);
        color: #fff;
        width: 100%;
        font-size: 15px;
        font-weight: 700;
        line-height: 17.58px;
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;

        &:disabled {
            opacity: 0.8;
        }
    }
}