.client-message {
  
  display: inline-block;
  background-color: #ffffff;
  
  border: solid 1px #d9d9d9;
  border-radius:20px;

  padding: 10px 13px; 
  
  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  overflow-wrap: anywhere;
  white-space: pre-line;
}
