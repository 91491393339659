.system-message {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: #303030;
  /* Line break in html with \n  */
  white-space: pre-line;
  text-align: center;
  line-height: 1.28;
}

.system-message * {
  max-width: 500px;
}
