.cl-shedule-button {
  height: 31px;
  border-radius: 16px;
  

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  background-color: var(--cta);
}

.cl-shedule-button i {
  display: inline-block;
  background-image: url(../../../../public/images/schedule-message-icon-white.png);
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

@media only screen and (max-width: 1030px) {
  .cl-shedule-button {
    font-size: 0;
  }
}