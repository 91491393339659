.modal-content.notifications-blocked-modal  {
    width: 411px;
    padding: 0;
    border-radius: 8px;
}

.modal-content.notifications-blocked-modal > div  {
    padding: 40px;
}

.notifications-blocked-modal h2 {
    margin-bottom: 15px;
}

.notifications-blocked-modal p {
    margin-bottom: 25px;
    line-height: 20px;
}

.notifications-blocked-modal a {
    line-height: 20px;
    color: #303030;
}