.fee-picker {
    font-size: 16px;
}

.fee-picker button.edit {
    margin-left: 7px;
}

.fee-picker button.edit i {
    background-image: url(/public/images/feather-fee-edit.svg);
    width: 12px;
    height: 12px;
}

.fee-picker .editable {
    display: inline-block;
}

.fee-picker .editable:hover {
    font-weight: bold;
    cursor: pointer;
}

.fee-picker * {
    vertical-align: baseline;
}

.fee-picker .editable:hover button.edit i {
    width: 14px;
    height: 14px;
}

.fee-picker-popup {
    position: absolute;
    background: #fff;
    z-index: 1;
    color: #303030;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding-top: 8px;
    padding-bottom: 24px;
    padding-left: 14px;
    top: 14px;
}

.fee-picker-popup .header {
    display: flex;
    padding-right: 10px;
    justify-content: space-between;
    margin-bottom: 30px;
    color: #892788;
}

.fee-picker-popup button.close i {
    background-image: url(/public/images/close.svg);
    width: 14px;
    height: 14px;
}

.fee-picker-popup .content {
    font-size: 12px;
    font-weight: 500;
    padding-right: 14px;
}

.fee-picker-popup label {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 22px;
}

.fee-picker-popup .label-content {
    position: relative;
    margin-top: 4px;
}

.fee-picker-popup label span {
    position: absolute;
    top: 6px;
    left: 7px;
    font-size: 16px;
    line-height: 20px;
}

.fee-picker-popup input {
    border: solid 1px #aeadad;
    padding: 5px;
    padding-left: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    border-radius: 4px;
    width: 91px;
    margin:0;
}


/* Chrome, Safari, Edge, Opera */
.fee-picker-popup input::-webkit-outer-spin-button,
.fee-picker-popup input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.fee-picker-popup input[type=number] {
  -moz-appearance: textfield;
}



.fee-picker-popup input:focus {
    border: solid 1px #892788;
    outline-width: 0;
}

.fee-picker-popup button.change {
    background-color: #892788;
    color: #fff;
    vertical-align: bottom;
}

.fee-picker-popup button.change[disabled] {
    opacity: 0.7;
}

.fee-picker-popup div.error {
    position: absolute;
    line-height: 18px;
    color: #ce3d3d;
}

.fee-picker-popup input.has-error,
.fee-picker-popup input:focus.has-error
 {
     border-color: #ce3d3d;
 }


