.modal-content.block-client-confirm-modal {
  width: 436px;
  padding: 0;
  border-radius: 8px;

  > div {
    padding: 40px 40px 36px 40px;
  }

  h2 {
    margin-bottom: 23px;
    line-height: 30px;
  }
  .actions {
    display: flex;
    justify-content: space-around;
  }
  button {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.44px;
    white-space: nowrap;
    padding: 17px 22px;
  }
  button.primary {
    min-width: 140px;
    background-color: #fff;
    border: solid 2px #892788;
    color: #892788;
  }
  button.primary.confirm {
    background-color: #892788;
    border: solid 2px #892788;
    color: #fff;
    min-width: 172px;
  }
}
