.modal-content.group-message-confirm-modal {
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e6e6ee;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
        margin-top: 14px;
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
    }

    .confirm-message {
        margin-top: 5px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
    }

    button {
        margin-top: 41px;
        background: #892788;
        height: 46px;
        width: 299px;
        border-radius: 32px;
        color: #fff;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
    }
}
