.topBar .expert-status {
  display: flex;
  margin-right: 37px;
  padding-top: 6px;
}
.topBar .expert-status > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 7px;
}

.topBar .expert-status .advisor-name {
  font-weight: bold;
  font-size: 15px;
  padding-left: 2px;
  line-height: 20px;
  color: #615573;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 115px;
}
