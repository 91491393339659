.send-button {
    min-width: 67px;
    min-height: 67px;
    background-image: url(../../../public/images/feather-send.svg);
    background-position: 8px center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-left: 14px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    cursor: pointer;

    background-color: var(--cta);
}

.send-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}