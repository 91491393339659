.top-bar-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;

  color: #666666;
  margin-right: 21px;
  cursor: pointer;

  &.active {
    font-weight: bold;
    color: #892788;
    cursor: default;
  }
}