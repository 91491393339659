.client-card-container {
  .client-card {
    position: fixed;
    width: 580px;
    z-index: 1000;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 150ms cubic-bezier(0, 0, 0.35, 1);
    border-left: 1px solid #e1e1e1;
    &.blocked {
      background-color: #f0f0f0;
    }

    &.open {
      transform: none;
      box-shadow: 0px 0px 10px 0px #999;

      &:hover {
        .client-card-overlay {
          background: rgba(41, 47, 76, 0.5) !important;
        }
      }
    }

    .close {
      background-image: url(../../../public/images/close.svg);
      background-size: cover;
      background-repeat: no-repeat;
      height: 17px;
      width: 17px;
      cursor: pointer;
      display: inline-block;
    }
    .client-card-content {
      padding-top: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .client-card-controls {
        text-align: right;
        padding-right: 16px;
        margin-bottom: 3px;
        .fav-red-icon,
        .fav-white-icon {
          display: inline-block;
          margin-right: 30px;
          background-size: contain;
          width: 20px;
          height: 17px;
        }
        .block-icon,
        .blocked-icon,
        .block-client-loader {
          display: inline-block;
          margin-right: 30px;
          min-height: 17px;
        }
      }
      .client-card-body-wrapper {
        flex-grow: 1;
        position: relative;
      }
      .client-card-body {
        padding-left: 27px;
        padding-right: 28px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-y: auto;
        overflow-y: overlay;
        display: flex;
        flex-direction: column;

        .tag-wrapper {
          display: inline-block;
          margin-left: 5px;
        }

        .client-card-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 11px;

          .client-summary {
            display: flex;
            align-items: baseline;

            .client-alias {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 250px;
            }

            .client-dob {
              margin-left: 18px;
            }
          }
        }
      }
      .client-card-info {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 38px;
        span {
          margin-right: 12px;
        }
        .paid {
          color: #303030;
          font-weight: 700;
          margin-right: auto;
        }
        .amount {
          font-weight: 700;
          margin-right: auto;
          color: var(--cta);
        }

        .question-mark {
          display: inline-block;
          background-size: 100% 13px;
          background-image: url(../../../public/images/question-mark.svg);
          width: 13px;
          height: 13px;
          background-repeat: no-repeat;
          position: relative;
          margin-left: 2px;
        }
      }
      .client-card-tabs-nav {
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        color: #aeadad;
        margin-left: -6px;
        border-bottom: 1px solid #d9d9d9;
        > div {
          padding-left: 6px;
          padding-right: 6px;
          padding-bottom: 3px;
          cursor: pointer;

          &:first-child {
            //margin-right: 131px;
          }

          &.active,
          &:hover {
            color: var(--main);
            border-bottom: 3px solid var(--main);
          }
        }
      }
      .client-card-tabs-content {
        position: relative;
        flex-grow: 1;

        .simple-loader {
          margin-top: 40px;
          .circle {
            margin: 0 auto;
          }
        }
      }
    }

    .client-card-note {
      padding-top: 16px;
      padding-bottom: 25px;

      .note-title-editor-new {
        margin-bottom: 7px;
      }

      .note-editor.not-editable {
        min-height: 256px;
        // max-height: 256px;
      }
      .note-editor.editable {
        min-height: 256px;
      }

      .note-editor.not-editable .DraftEditor-root {
        min-height: 202px;
      }
    }

    .transcript {
      position: fixed;
      width: 580px;
      z-index: 2000;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #fff;
      transform: translateX(100%);
      transition: transform 150ms cubic-bezier(0, 0, 0.35, 1);
      border-left: 1px solid #e1e1e1;
      &.open {
        transform: none;
        box-shadow: 0px 0px 10px 0px #999;
      }
    }
  }

  .client-card-last-reading {
    padding-top: 25px;
    .mute-text {
      font-weight: bold;
      margin-bottom: 11px;
    }
    .load-more {
      height: 55px;
      .simple-loader {
        margin-top: 0 !important;
      }
    }
  }

  .client-card-overlay {
    right: 100%;
    width: 3000px;
    top: 0px;
    bottom: 0px;
    position: absolute;
    transition: background 0.15s ease;
    pointer-events: none;
  }

  .client-card-loader {
    .simple-loader {
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0);
    }
  }
}

.tool-tip-up {
  z-index: 1000;
}
