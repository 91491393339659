.topBar .status {
    font-size: 12px;
    line-height: 20px;
    
}

.topBar .status::before,
.status-picker-popup-content .status::before {
    content:"\A";
    width:8px;
    height:8px;
    border-radius:50%;
    background: #000;
    display:inline-block;
    margin-right: 2px;
}

.topBar .status.available,
.status-picker-popup-content .status.available
{
    color: #8dc440;
    cursor: pointer;
}

.topBar .status.available::before,
.status-picker-popup-content .status.available::before
{
    background:#8dc440;
    cursor: pointer;
}

.topBar .status.available:hover,
.topBar .status.away:hover {
    background-color: #eee;
}

.topBar .status.away,
.status-picker-popup-content .status.away
 {
    color: #666666;
    cursor: pointer;
}

.topBar .status.away::before,
.status-picker-popup-content .status.away::before
 {
    background:#666666;
    
}

.topBar .status.suspended {
    color: #CE3D3D;
}

.topBar .status.suspended::before {
    background:#CE3D3D;
}

.topBar .status.busy {
    color: #EF762B;
}

.topBar .status.busy::before {
    background:#EF762B;
}


.status-picker-popup-content {
    background-color:  #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    font-size: 12px;
    padding: 0px 0px 4px 5px;
    top: 0px;
    left: -5px;
    width: 83px;
    position: absolute;
    display: none;
}

.status-picker-popup-content.opened {
    display: flex;
    flex-direction: column;
}

.status-picker-popup-content.opened.reverse {
    flex-direction: column-reverse;
}

.status-picker-popup-content .status {
    margin-bottom: 1px;
}

.topBar .status-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.topBar .status-wrapper .simple-loader {
    margin-left: 5px;
}
