.smile-icon {
    display: inline-block;
    background-size: 100% 24px;
    background-image: url(/public/images/smile-face.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    position: relative;
    margin-right: 6px;
  }

  .sad-icon {
    display: inline-block;
    background-size: 100% 24px;
    background-image: url(/public/images/sad-face.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    position: relative;
    margin-right: 6px;
  }

