.vertical-menu {
  width: 236px; 
}
/* background-color: #eee;  */
.vertical-menu a {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #666666;

  display: block;
  padding: 12px;
  padding-left: 32px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #F3F3F7;
}

.vertical-menu a.active {
  font-weight: bold;
  background-color: #F3F3F7;

}