html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
}

body,
html,
#root {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    min-width: 600px;
    min-height: 500px;
    color: #303030;
}

header {
    position: relative;
    height: 60px;
    box-shadow: 0 2px 7px 0 rgba(213, 201, 240, 0.6);
    background-color: #ffffff;
    z-index: 1;
}

.main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

button {
    margin: 0;
    display: inline-block;
    color: #892788;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 5px 13px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    border-radius: 16px;
    cursor: pointer;
    outline: none;
    font-family: inherit;
}

button.icon {
    border: 0;
    padding: 0;
    background: none;
    border-radius: 0;
}

button.icon i {
    display: block;
    background-size: cover;
}

button.primary {
    background-color: var(--cta);
    color: #fff;
    padding: 12px 35px;
    border-radius: 32px;
}

button.white {
    padding: 7px 13px 7px 11px;
    border-radius: 17px;
    border: solid 1px #303030;
    background-color: #ffffff;
    color: #303030;
    font-weight: normal;
    font-size: 15px;
}

h1 {
    font-size: 30px;
    font-weight: bold;
}

h2 {
    font-size: 20px;
    font-weight: bold;
}

input[type="text"] {
    font-family: 'Roboto', sans-serif;
}

.modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(48, 48, 48, 0.6);
}

.modal-content {
    width: 400px;
    border-radius: 4px;
    background-color: #fff;
    padding: 50px;
    text-align: center;
    position: relative;
    outline: none;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin: 0;
    transform: translate(-50%, -50%);
    max-height: 95vh;
    overflow-y: auto;
    /* transform: translateY(-50%); */
}

.hidden-modal {
    display: none;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 1002;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.__react_component_tooltip {
    z-index: 1010!important;
}

.title {
    object-fit: contain;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    text-transform: uppercase;
}

.mute-text {
    color: #666666;
    font-size: 16px;
}

.__react_component_tooltip {
    max-width: 250px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.select-data-set-container {
    display: inline-block;
}

div.select-data-set {
    margin-right: 1px;
    display: inline-block;
    width: 177px;
    font-weight: bold;
    font-size: 14px;
    z-index: 10;
}

.select-data-set__control {
    border-radius: 4px;
    min-height: 40px;
}

.select-data-set__control--is-focused {
    border-color: #892788 !important;
    box-shadow: none;
}

.error .select-data-set__control {
    border-color: red !important;
    box-shadow: none;
}

.select-data-set__option--is-selected {
    background-color: #892788 !important;
}

.select-data-set__option--is-focused {
    background-color: rgba(137, 39, 136, 0.1);
}