.modal-content.notifications-allow-modal {
    width: 458px;
    padding: 0;
    border-radius: 8px;
}

.notifications-allow-modal > div {
    padding: 65px 50px 0px 50px;
}

.notifications-allow-modal img {
    margin-bottom: 30px;
}

.notifications-allow-modal h2 {
    margin-bottom: 14px;
}

.notifications-allow-modal p {
    margin-bottom: 35px;
    padding: 0 13px;
    line-height: 20px;
}

.notifications-allow-modal button {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.44px;
    margin-bottom: 56px;
    padding: 18px 57px;
}