.modal-content.block-client-high-valued-modal {
  width: 436px;
  padding: 0;
  border-radius: 8px;

  > div {
    padding: 40px 40px 36px 40px;
  }

  h2 {
    margin-bottom: 23px;
    line-height: 30px;
  }

  .text-message {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #303030;

    a {
      color: #892788;
    } /* Unvisited link  */
    a:visited {
      color: #892788;
    } /* Visited link    */
    a:hover {
      color: #892788;
    } /* Mouse over link */
    a:active {
      color: #892788;
    } /* Selected link   */
  }
}
