.bubble-icon {
  display: inline-block;
  background-size: 100% 16px;
  background-image: url(/public/images/bubble-icon.svg);
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  margin-right: 6px;
}

.phone-icon {
  display: inline-block;
  background-size: 100% 16px;
  background-image: url(/public/images/phone-icon.svg);
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  margin-right: 6px;
}
.video-icon {
  display: inline-block;
  background-size: 100% 16px;
  background-image: url(/public/images/video-icon.svg);
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  margin-right: 6px;
}
