.rating-widget {
  height: 100px;

  .rating-widget-title {
    padding: 16px;
    color: #303030;
    font-weight: 700;
  }

  .container {
    box-sizing: border-box;
    padding-left: 15px;

    h1 {
      position: relative;
      top: -55px;
      right: -430px;
      font-size: 40px;
    }
  }
}
