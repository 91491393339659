.incoming-chat-popup {
    width: 345px;
    position: fixed;
    top: 81px;
    right: -364px;
    border-radius: 20px;
    box-shadow: -1px 7px 5px 0 rgba(0, 0, 0, 0.36);
    border: solid 1px #d9d9d9;
    background-color: #ffffff;
    transition: right 0.5s;
    z-index: 1004;
}

.incoming-chat-popup.open {
    right: 19px;
}

.incoming-chat-popup > div {
    padding: 23px 26px 29px 26px;
}

.incoming-chat-popup_header {
    display: flex;
    font-size: 15px;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 7px;
}

.incoming-chat-popup_header span {
    color: #F89B22;
    font-size: 20px;
    font-weight: bold;
}

.incoming-chat-popup h1 {
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.incoming-chat-popup_user-id {
    margin-bottom: 31px;
    font-size: 15px;
}

.incoming-chat-popup_answer  {
    background: #F89B22;
    color: #fff;
    width: 100%;
    font-size: 20px;
    padding: 14px 35px;
    border-radius: 32px;
}

.incoming-chat-popup .tryout {
    display: flex;
    flex-direction: row;
}

.incoming-chat-popup .tryout img {
    margin-right: 7px;
    width: 33px;
    height: 33px;
}

.incoming-chat-popup .tryout div {
    display: flex;
    flex-direction: column;
}

.incoming-chat-popup .tryout span {
    font-family: Roboto;
    font-size: 16px;
    line-height: 18.75px;
    text-align: left;
    color: #454545;
    font-weight: 400;
}

.incoming-chat-popup .tryout .tryout-header {
    color: #F89B22;
    font-weight: 700;
}

