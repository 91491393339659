.revenues{
    width: 100%;
    height:100%;
    .simple-loader{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    iframe {
        width: 100%;
        height: 100%;
        border: none; /* Remove border */
      }
}