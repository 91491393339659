.session-page__session-summary {
  background-color: rgba(137, 39, 136, 0.05);
  bottom: 0;
  width: 100%;
  text-align: center;
  padding-top: 30px;
  color: #303030;

  p {
    margin-bottom: 20px;
  }

  .info {
    margin-bottom: 18px;

    & > div {
      display: inline-block;
      padding: 0 20px;
      border-right: 1px solid #8d308d;
    }

    .earnings {
      border-right: 0;
      font-weight: bold;
    }

    label {
      display: block;
      font-size: 12px;
    }

    span {
      font-size: 24px;
      vertical-align: baseline;
    }
  }

  .small-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 52px;

    & > span {
      &:first-child {
        padding-right: 14px;
      }

      font-size: 12px;
      padding-top: 2px;
    }

    .help-icon {
      display: inline-block;
      background-size: 100% 16px;

      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      cursor: pointer;

      margin-left: 6px;

      background-image: url(../../../public/images/help-widget-icon.svg);
    }

    #summary_tooltip {
      max-width: 183px;
      box-sizing: border-box;
      padding: 10px;
      background-color: #666;
      opacity: 1;
    }

    #summary_tooltip .tooltip-text {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      line-height: 13px;
      text-align: left;

      strong {
        font-weight: 700;
      }
    }
  }

  .summary-alert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 16px;

    .summary-alert_label {
      color: #ce3d3d;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 5px;
    }

    .summary-alert_hint {
      font-size: 12px;
    }
  }

  button {
    background-color: #f77f1e;
    color: #fff;
    padding: 18px 35px;
    margin-bottom: 30px;
    border-radius: 32px;
    font-size: 20px;
  }
}

.session-page__session-summary .scheduled-link {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 22px;
}

.session-page__session-summary .message-scheduled {
  font-size: 21px;
  margin-bottom: 22px;
}

.session-page__session-summary .scheduled-link a {
  text-decoration: none;
  color: #8d308d;
}

.session-page__session-summary .scheduled-link .simple-loader {
  display: inline-block;
  margin-left: 5px;
}

.session-page__session-summary .scheduled-link a:before {
  content: url(../../../public/images/scheduleMessage-icon.svg);
  position: relative;
  top: 3px;
  margin-right: 5px;
}

.session-page__session-summary .simple-loader .circle {
  margin: 0 auto;
}
