.on-boarding-tool-tip-wrapper {
  display: flex;
  flex-direction: column;

  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin: 10px !important;
}

.on-boarding-tool-tip-title {
  font-size: 15px;
  line-height: 1.33;
  font-weight: bold;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.on-boarding-tool-tip-body {
  font-size: 16px;
  line-height: 1.31;
  margin-bottom: 13px;
}

.on-boarding-tool-tip-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.on-boarding-tool-tip-button {
  border-radius: 13px;
  background-color: #ffffff;

  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.on-boarding-tool-tip-button.next {
  width: 68px;
  height: 25px;
}
.on-boarding-tool-tip-button.finish {
  width: 120px;
  height: 25px;
}

.on-boarding-tool-tip-button-text {
  font-size: 12px;
  font-weight: bold;
  color: #4387d9;
  text-transform: uppercase;
}

.tippy-box[data-theme~="wem"] {
  color: white;
  background-color: #4387d9;
  border-radius: 10px;
}
.tippy-box[data-theme~="wem"][data-placement^="top"] > .tippy-arrow:before {
  border-top-color: #4387d9;
}
.tippy-box[data-theme~="wem"][data-placement^="bottom"] > .tippy-arrow:before {
  border-bottom-color: #4387d9;
}
.tippy-box[data-theme~="wem"][data-placement^="left"] > .tippy-arrow:before {
  border-left-color: #4387d9;
}
.tippy-box[data-theme~="wem"][data-placement^="right"] > .tippy-arrow:before {
  border-right-color: #4387d9;
}
.tippy-box[data-theme~="wem"] > .tippy-backdrop {
  background-color: #4387d9;
}
.tippy-box[data-theme~="wem"] > .tippy-svg-arrow {
  fill: #4387d9;
}
