.active-reading-popup {
    width: 302px;
    position: fixed;
    top: 142px;
    right: -364px;
    border-radius: 5px;
    box-shadow: -1px 7px 5px 0 rgba(0, 0, 0, 0.36);
    border: solid 1px var(--active-session-border);
    background-color: #ffffff;
    transition: right 0.5s;
    z-index: 999;

    &.open {
        right: -20px;
    }

    &>div {
        padding: 16px 20px;
        display: flex;
        flex-direction: row;
        column-gap: 38px;
        align-items: center;
    }

    .client-info {
        display: flex;
        flex-direction: column;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        text-align: left;

        .client-name {
            font-weight: 700;
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100px;
            display: inline-block;
        }
    }

    .view-client {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.75px;
        width: 104px;
        height: 31px;
        border-radius: 70px;
        background: var(--cta);
        color: #fff;
        padding: 0;
    }
}