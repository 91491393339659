.expert-message {
  display: inline-block;

  border-radius: 20px;

  padding-left: 13px;
  padding-right: 13px;
  padding-top: 10px;
  padding-bottom: 10px;

  font-family: Roboto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  overflow-wrap: anywhere;
  background-color: var(--main);
  white-space: pre-line;
}
