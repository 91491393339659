.note-title-editor {
  display: flex;
  margin-bottom: 11px;
  flex-direction: column;
  max-width: 305px;

  .note-title-input {
    height: 24px;
    border: none;
    color: #666666;
    outline: none;
    flex-grow: 1;
    min-width: 0px;
    background-repeat: no-repeat;
    background-position: right;
    font-size: 16px;
    padding: 0;
    border-bottom: solid 1px #aeadad;
  }

  .note-title-input:first-line {
    display: inline-block;
  }

  .read-title {
    cursor: pointer;
    margin: 0 8.5px 0 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
  }

  .read-title div:after {
    background-image: url(../../../public/images/feather-edit.svg);
    margin-left: 7px;
    content: "";
    display: inline-block;
    height: 16px;
    width: 19px;
    background-size: 19px 16px;
    background-repeat: no-repeat;
    vertical-align: text-top;
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #adadad;
  }
  .row {
    margin-bottom: 10px;
  }

  label.select-month {
    margin-bottom: 5px;
    display: block;
  }

  div.select-month {
    margin-bottom: 11px;
    margin-right: 25px;
    display: inline-block;
    width: 84px;
  }

  .select-month__control {
    border-radius: 4px;
    min-height: 40px;
  }

  .error .select-month__control {
    border-color: red !important;
    box-shadow: none;
  }

  .select-month__option--is-focused {
    background-color: rgba(137, 39, 136, 0.1);
  }

  input[type="text"] {
    box-sizing: border-box;
    border-radius: 4px;
    border: solid 1px #adadad;
    padding: 8px 8px 8px 8px;

    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
  }
  .error-message {
    color: red;
    font-size: 13px;
  }

  .error {
    border-color: red !important;
  }

  .title-form {
    width: 100%;
    position: relative;
    margin-bottom: 23px;
  }
  .client-name-edit {
    margin-right: 16px;
    width: 100%;
  }

  .day {
    width: 79px;
    margin: 0px 28px 11px 0px;
  }

  .year {
    width: 79px;
    margin-right: 0;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #adadad;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #adadad;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #adadad;
  }

  input[type="text"]:focus {
    outline: none !important;
    border-color: var(--main);
  }

  .select-month__control--is-focused {
    border-color: var(--main) !important;
    box-shadow: none;
  }

  .select-month__option--is-selected {
    background-color: var(--main) !important;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.note-title-editor-input {
  border-radius: 4px;
  border: 1px solid #adadad;
  padding: 8px;
  width: 55px;
  margin: 0 28px 11px 0;
  font-size: 17px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
}

.note-title-editor-input:active {
  background: none;
}

.note-title-editor-input:not(:placeholder-shown) {
  background: none;
}

.selectMonth {
  margin-bottom: 11px;
  margin-right: 25px;
  display: inline-block;
  width: 94px;
  z-index: 1000;
}
