.cl-header-container {
  padding-left: 32px;
  padding-top: 29px;
  padding-bottom: 29px;
  background-color: #fafafd;
  min-width: 900px;
  display: flex;
  justify-content: space-between;

  .cl-title {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #303030;
  }
}
