.chart-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: relative;

  .chart-column-container {
    width: 33px;
    padding: 2px;
    border: 1px solid #fff;
    border-radius: 20px;
    box-sizing: border-box;
    margin-bottom: 5px;

    .chart-column-inner-container {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }

    &.high {
      &.selected {
        border-color: #5adc20;
      }
      .chart-column-inner-container {
        background: linear-gradient(#5adc20, #92ff88);
      }
    }

    &.medium {
      &.selected {
        border-color: #99bbc6;
      }
      .chart-column-inner-container {
        background: linear-gradient(#99bbc6, #d6eaf0);
      }
    }

    &.low {
      &.selected {
        border-color: #ff0000;
      }
      .chart-column-inner-container {
        background: linear-gradient(#ff0000, #ffb2b2);
      }
    }
  }

  .chart-column-ammount {
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  img {
    width: 22px;
    height: 11px;
    position: absolute;
    bottom: 0;
  }
}
