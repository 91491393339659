.cl-result {
  display: flex;

  justify-content: left;
  flex-direction: column;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.cl-result thead {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  border-spacing: 0;
}

.cl-result table {
  border-collapse: collapse;
  margin-bottom: 20.9px;
  table-layout: fixed;
  width: 100%;
}

.cl-not-found-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.cl-paging-loader-wrapper {
  text-align: center;
}

.cl-paging-loader-wrapper .simple-loader {
  display: inline-block;
}


.col-faivorite {
  width: 3%;
}

.col-client-name {
  width: 11%;
}

.col-client-alias {
  width: 11%;
}

.col-client-dob {
  width: 8%;
}

.col-client-gender {
  width: 8%;
}

.col-client-id {
  width: 8%;
}

.col-session-count {
  width: 7%;
}

.col-last-reading {
  width: 15%;
}

.col-instant-message {
  width: 15%;
}

.col-actions {
  width: 11%;
}

.col-offers {
  width: 7%;
}


@media only screen and (max-width: 1829px) {
  .col-client-name {
    width: 10%;
  }

  .col-client-alias {
    width: 10%;
  }

  .col-client-dob {
    width: 6%;
  }

  .col-client-gender {
    width: 6%;
  }

  .col-client-id {
    width: 7%;
  }

  .col-session-count {
    width: 7%;
  }

  .col-last-reading {
    width: 15%;
  }

  .col-instant-message {
    width: 15%;
  }

  .col-actions {
    width: 11%;
  }

  .col-offers {
    width: 6%;
  }
}

@media only screen and (max-width: 1400px) {

  .col-client-name {
    width: 8%;
  }

  .col-client-alias {
    width: 9%;
  }

  .col-client-gender {
    width: 5%;
  }

  .col-session-count {
    width: 5%;
  }

  .col-last-reading {
    width: 10%;
  }

  .col-instant-message {
    width: 10%;
  }

  .col-offers {
    width: 5%;
  }
}

@media only screen and (max-width: 1030px) {
  .col-actions {
    width: 8%;
  }

  .col-offers {
    width: 7%;
  }
}