.arrow-btn {
    width: 20px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: #fff;
    z-index: 1;

    &.right {
        transform: rotate(180deg);
    }
    
    div {
        display: flex;
    }

    svg {
        stroke: var(--main);
    }
}