.session-page__top-bar {
  height: 45px;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 4%;
  padding-right: 4%;
  background: var(--session-top-bar);
}

.session-page__top-bar__fee-picker {
  margin-right: auto;
  flex-grow: 1;
}

.session-page__top-bar__timer {
  font-weight: bold;
  margin-right: 13px;
  display: flex;
  flex-direction: column;
}

.session-page__quit-session:active {
  background-color: #eee;
}

.session-page__quit-session[disabled] {
  opacity: 0.3;
}

.timer {
  text-align: center;
}

.tryout {
  color: #F68346;
  font-size: 13px;
}
