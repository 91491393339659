.waiting-client-modal {
  box-sizing: border-box;
  padding: 52px;
  border-radius: 8px;

  .modal-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 23px;
  }

  h2 {
    font-family: Roboto;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
  }

  p {
    font-family: Roboto;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
  }

  button {
    background-color: var(--main);
  }

  button:disabled {
    background-color: #aeadad;
    color: #fff;
  }

  button .counter {
    margin-right: 5px;
  }
}
