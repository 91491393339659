.cl-data-row td {
  border-bottom: 2px solid #e6e6ee;
  border-spacing: 0;
  word-wrap: break-word;
}

.cl-data-row:hover,
.cl-data-row.selected {
  background-color: #eee;
  cursor: pointer;
}

.cl-data-row-withbtn td {
  padding: 12px;
}

.cl-data-row-wobtn td {
  padding: 14px 10px 15px;
}

.cl-data-row-woread td {
  padding: 16px 10px 16px;
}

.cl-data-row-unread {
  font-weight: bold;
}

.td-scheduled-message .text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
  white-space: break-spaces;
}

.fav-icon-td {
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
}

.td-scheduled-message {
  white-space: nowrap;
}

.mark-ok-icon {
  display: inline-block;
  background-size: 100%;

  width: 13px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url(/public/images/mark-ok-icon.svg);

  margin-right: 5px;
}

.td-client-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unread-messages-icon {
  margin-right: 4px;
  margin-bottom: -2px;
}

.add-text {
  color: #adadad;
}

.td-client-gender {
  vertical-align: middle;
}