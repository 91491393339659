.blacklist-alert-modal {
    width: 400px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e6e6ee;

    h1 {
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0em;
        margin-bottom: 15px;
    }

    div {
        font-family: Roboto;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 15px;
    }
}
