.earnings-summary-container {
  padding: 16px;

  .earnings-header {
    margin-top: 12px;
    font-weight: 600;
    font-size: 13px;
    color: #7d7d7d;
  }

  .earnings-sum {
    display: flex;
    margin-top: 11px;
    .value {
      font-size: 54px;
      font-weight: 700;
      line-height: 1;
    }
    .trending {
      margin: 4px 0 0 8px;
    }
  }

  .badges {
    display: flex;
    flex-direction: row;
  }

  .service-earnings-list {
    display: flex;
    flex-direction: column;

    .service-earning-item {
      margin-top: 10px;

      .service-earning-title {
        font-size: 13px;
        font-weight: 700;
      }

      .service-earning-row {
        display: flex;
        flex-direction: row;

        .star {
          box-sizing: border-box;
          margin: 0 3px 2px 0;
          background-color: #5adc20;
          width: 12px;
          height: 12px;
          mask-image: url(../../../../public/images/filled_star.svg);
          mask-size: contain;
        }

        .amount {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
}