.modal-content.quit-session-modal {
    width: 436px;
    padding: 0;
    border-radius: 8px;
}

.modal-content.quit-session-modal > div {
    padding: 40px 40px 36px 40px;
}

.modal-content.quit-session-modal h2 {
    margin-bottom: 23px;
    line-height: 30px;
}
.quit-session-modal .actions {
    display: flex;
    justify-content: space-between;
}

.quit-session-modal button {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.44px;
    white-space: nowrap;
    padding: 17px 22px;
}

.quit-session-modal button.confirm {
    background-color: #fff;
    border: solid 2px #892788;
    color: #892788;
}