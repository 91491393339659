.transcript-phrase {
  .tr-ph-header {
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 5px;
    
    &.expert {
      color: var(--main);
    }
    &.client {
      color: #303030;
    }
  }

  .tr-ph-sentance {
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
    overflow-wrap: break-word;
    white-space: pre-line;
  }
}
