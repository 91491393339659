.simple-loader .circle {
    width: 50px;
    height: 50px;
    border: 3px solid #BDBDBD;
    border-radius: 100%;
    animation: rotate-circle 1s linear infinite;
    border-top-color: var(--main);
}

@keyframes rotate-circle {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}