.modal-content.scheduled-message {
    width: 455px;
    padding: 0;
    text-align: left;
    padding:  48px 53px 10px 53px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e6e6ee;
    font-size: 15px;
}


.modal-content.scheduled-message .simple-loader {
    display: inline-block;
}

.modal-content.scheduled-message h2 {
    line-height: 1.3;
    color: #892788;
    margin-bottom: 10px;
}


.modal-content.scheduled-message .close {
    background-image: url(/public/images/close.svg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 17px;
    width: 17px;
    position: absolute;
    right: 26px;
    top: 23px;
    cursor: pointer;
}

.modal-content.scheduled-message h3 {
    margin-bottom: 23px;
}

.modal-content.scheduled-message textarea {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    display: block;
    width: 100%;
    height: 143px;
    margin-top: 5px;
    line-height: 1.33;
    border-radius: 8px;
    padding: 10px;
    border: solid 1px #707070;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 11px;
}
.modal-content.scheduled-message textarea:read-only {
    background-color: #f4f4f4;
    border: solid 1px #707070!important;
}

.modal-content.scheduled-message .error-message {
    color: red;
    font-size: 13px;
}

.modal-content.scheduled-message .error {
    border-color: red!important;
}

.modal-content.scheduled-message textarea:focus {
    outline: none!important;
    border-color: #892788;
}
.modal-content.scheduled-message label.text  {

    display: block;
    margin-bottom: 5px;
}

.modal-content.scheduled-message label.shedule-time  {
    margin-bottom: 5px;
    display: block;
}

.modal-content.scheduled-message div.shedule-time  {
    margin-bottom: 32px;
}

.modal-content.scheduled-message .shedule-time__control {
    border-radius: 8px;
    min-height: 40px;
}

.modal-content.scheduled-message .shedule-time__control,
.modal-content.scheduled-message .message-template__control {
    border-color: #707070;
}

.modal-content.scheduled-message .shedule-time__control--is-focused,
.modal-content.scheduled-message .message-template__control--is-focused {
    border-color: #892788!important;
    box-shadow: none;
}

.modal-content.scheduled-message .error .shedule-time__control {
    border-color: red!important;
    box-shadow: none;
}



.modal-content.scheduled-message  .shedule-time__option--is-selected {
    background-color: #892788!important;
}

.modal-content.scheduled-message  .shedule-time__option--is-focused{
    background-color: rgba(137, 39, 136, 0.1);
}

.modal-content.scheduled-message button {
    width: 100%;
    margin-bottom: 10px;
}

.modal-content.scheduled-message p {
    font-size: 13px;
    margin-bottom: 43px;
}
