button.white.promotion-message-button {
    padding: 0;
    width: 51px;
    padding-right: 5px;
    padding-top: 3px;
    box-sizing: border-box;
    .present {
        fill: #303030;
    }
    .plus {
        fill: none;
        stroke: #303030;
        stroke-linecap: round;
        stroke-width: 1.5px;
    }
    &.active {
        .present {
            fill: #892788;
        }
        .plus {
            stroke: #892788;
        }
    }

    &.disabled {
        border-color: #aeadad;
        .present {
            fill: #aeadad;
        }
        .plus {
            stroke: #aeadad;
        }
        
    }
}