.question-mark {
  display: inline-block;
  background-size: 100% 17px;
  background-image: url(../../../../public/images/question-mark.svg);
  width: 17px;
  height: 17px;
  top: 3px;
  background-repeat: no-repeat;
  position: relative;
  margin-left: 6px;
}