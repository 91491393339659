.terms-modal {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 36px;
  box-sizing: border-box;
  border-radius: 16px;

  .logo {
    width: 60px;
    height: 60px;
    margin-bottom: 8px;
  }

  h3 {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;
    margin-bottom: 24px;
  }

  .accept-checkbox {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background: linear-gradient(0deg, #ffffff, #ffffff),
      linear-gradient(0deg, #dfe2ef, #dfe2ef);
    border: 1px solid #dfe2ef;
    margin: 0 10px 0 0;

    &:checked {
      accent-color: var(--main);
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    font-family: Inter;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;

    a {
      color: var(--main);
      font-weight: 700;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  button {
    width: 100%;
    height: 48px;
    padding: 0px 16px;
    border-radius: 8px;
    background: var(--main);
    color: #fff;
    margin-bottom: 24px;
    font-family: Inter;
    font-size: 17px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;

    &:disabled {
      background: #d3d3d3;
    }
  }

  .need-help {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #636570;
    text-align: center;
    width: 100%;

    a {
      color: inherit;
    }
  }
}
